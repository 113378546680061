import React from 'react';
import {
    AlertDialog,
    Button,
    HStack,
    Spacer,
    Text,
    VStack,
} from 'native-base';
import {useAuthContext} from '../../context/auth.context';
import fetcher from '../../services/fetcher';
import PButton from "../base/pButton";

function AltertDialog(props: {
    leastDestructiveRef: React.MutableRefObject<null>,
    open: boolean,
    onClose: () => void
}) {
    return (<AlertDialog leastDestructiveRef={props.leastDestructiveRef} isOpen={props.open} onClose={props.onClose}>
        <AlertDialog.Content>
            <AlertDialog.CloseButton/>
            <AlertDialog.Header>Parabéns!</AlertDialog.Header>
            <AlertDialog.Body>
                Seu cupom foi resgatado com sucesso!.
            </AlertDialog.Body>
            <AlertDialog.Footer>
                <Button.Group space={2}>
                    <PButton text={"OK"} onPress={props.onClose}/>
                </Button.Group>
            </AlertDialog.Footer>
        </AlertDialog.Content>
    </AlertDialog>)
}

function TicketCardTitle(props: { ticketBody: TTicketBody[] }) {
    return (<>
        <Text
            fontSize="md"
            fontWeight="bold"
            _light={{color: 'tx.title'}}
        >
            Parabéns!!!
        </Text>
        <HStack>
            <Text
                fontSize="sm"
                fontWeight="medium"
                _light={{color: "tx.subTitle"}}
                style={{flex: 1, flexWrap: "wrap"}}
                mt={2} ml={2}
            >
                {
                    props.ticketBody.map((ticketBody, index) => {
                        return (<p key={index}>{`${ticketBody.MENSAGEM}\n`}</p>)
                    })
                }
            </Text>
        </HStack>
    </>)
}

function TicketCardRules(props: { ticketBody: TTicketBody[] }) {
    return (
        <>
            <Text
                fontSize="md"
                fontWeight="bold"
                _light={{color: 'tx.title'}}
            >
                Regras
            </Text>
            <HStack>
                <Text
                    fontSize="sm"
                    fontWeight="medium"
                    _light={{color: "tx.subTitle"}}
                    style={{flex: 1, flexWrap: "wrap"}}
                    mt={2} ml={2}
                >
                    {
                        props.ticketBody.map((ticketBody, index) => {
                            return (<li key={index}>{`${ticketBody.MENSAGEM}\n`}</li>)
                        })
                    }
                </Text>
            </HStack>
        </>)
}

function TicketCardFooter(props: { text: string, onPress: () => Promise<void> }) {
    return (<HStack m={5} alignContent={"space-between"}>
        <Spacer></Spacer>
        <PButton
            text={props.text} onPress={props.onPress}
        >
        </PButton>
    </HStack>)
}

function TicketCardBuild(props: {
    ticket: TTicket,
    showButton: boolean
    onPress: () => Promise<void>,
    leastDestructiveRef: React.MutableRefObject<null>,
    open: boolean,
    onClose: () => void
}) {
    return (<>
        <VStack
            _light={{bg: 'bg.primary'}}
            borderRadius={{base: '8'}}
            p={{base: 4}}
            mb={{md: 2}}
        >
            <HStack alignItems="center" justifyContent="space-between">
                <VStack space="1">
                    <Text
                        fontSize="sm"
                        color="tx.clear"
                        fontWeight="semibold"
                        letterSpacing="0.2"
                    >
                        {props.ticket.TIPO === "C" ? "Cupom Liberado" : "Promoção Liberada"}
                    </Text>
                </VStack>
            </HStack>
        </VStack>
        <VStack p={4} space={1}>
            <TicketCardTitle ticketBody={props.ticket.CONTEUDO.filter(ticketBody => ticketBody.TIPOCONTEUDO == 'TT')}/>
            <TicketCardRules ticketBody={props.ticket.CONTEUDO.filter(ticketBody => ticketBody.TIPOCONTEUDO == 'RG')}/>
            {props.showButton && (
                <TicketCardFooter text={props.ticket.TIPO === "C" ? "Resgatar" : "Ativar Promoção"} onPress={props.onPress}/>)
            }
        </VStack>
        <AltertDialog leastDestructiveRef={props.leastDestructiveRef} open={props.open}
                      onClose={props.onClose}/>
    </>)
}

export default function TicketCard() {
    const {session} = useAuthContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const cancelRef = React.useRef(null);
    const [ticketData, setTicketData] = React.useState<TTicket[]>();

    React.useEffect(() => {
        const execute = async () => {
            await getTicket();
        }
        execute();
    }, []);
    const onClose = () => {
        setIsOpen(false)
        setTicketData(undefined)
    };

    const getTicket = async (): Promise<void> => {
        await fetcher<TTicket[]>(
            'POST',
            'cashback/cupom/liberadoportoken',
            {
                token: session.usuarioPortal.TOKEN,
            }
        ).then((res) => {
            if (res.ok) {
                if (res.return.data !== undefined) {
                    if (res.return.data.length > 0) {
                        setTicketData(res.return.data)
                    }
                }
            }
        }).catch((err) => {
            console.log((err as Error).message);
        });
    };

    const setTicketRescue = async (codCupom: number): Promise<void> => {
        await fetcher<TTicket[]>(
            'POST',
            'cashback/cupom/registrarresgate',
            {
                token: session.usuarioPortal.TOKEN,
                codcupom: codCupom
            }
        )
            .then((res) => {
                if (res.ok) {
                    if (res.return.data !== undefined) {
                        if (res.return.status) {
                            setIsOpen(!isOpen)
                        }
                    }
                }
            })
            .catch((err) => {
                console.log((err as Error).message);
            });
    };

    /*if (ticketData === undefined)
        return null
    else if (ticketData.length > 0) {
        ticketData.map(ticket => {
            return (
                <TicketCardBuild ticket={ticket}
                                 onPress={() => setTicketRescue(ticket.CODCUPOM)}
                                 leastDestructiveRef={cancelRef}
                                 open={isOpen}
                                 onClose={onClose}/>
            );
        })
    } else return null
    */
    if (ticketData === undefined)
        return null

    {
        return (<>
            {
                ticketData.map(ticket => {
                    return (<>
                        <TicketCardBuild ticket={ticket}
                                         showButton={!session.usuarioPortal.ACESSADO_POR_RCA}
                                         onPress={() => setTicketRescue(ticket.CODCUPOM)}
                                         leastDestructiveRef={cancelRef}
                                         open={isOpen}
                                         onClose={onClose}/>

                    </>)
                })

            }
        </>)
    }
}
