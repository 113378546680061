import React from "react";
import {
    Box,
    VStack,
    Image,
    StatusBar,
    Center,
    Text,
} from "native-base";
import {useRoute} from "@react-navigation/native";
import {DeepLinkExtratoRouteProp} from "../../routes/param";
import {useAuthContext} from '../../context/auth.context';

export default function DeepLinkExtrato(props: any) {
    const route = useRoute<DeepLinkExtratoRouteProp>();
    const {loginById} = useAuthContext();

    const login = async () => {
        const id = route.params?.id
        const type = route.params?.type
        if (id === undefined) return
        const isAuthenticated = await loginById(id, type?.toUpperCase() === 'R');
        if (isAuthenticated) {
            props.navigation.navigate('HomePages');
        } else {
            props.navigation.navigate('Splash');
        }
    }

    React.useEffect(() => {
        const execute = async () => {
            await login();
        }
        execute().then(r => r);
    }, []);

    return (
        <>
            <StatusBar
                translucent
                backgroundColor="transparent"
                barStyle="light-content"
            />
            <Box
                safeAreaTop
                _dark={{bg: "coolGray.900"}}
                _light={{bg: "primary.50"}}
            />

            <VStack
                alignItems="center"
                w="100%"
                flex="1"
                _dark={{bg: "coolGray.900"}}
                _light={{bg: "primary.50"}}
            >
                <Box maxW="500" w="100%">
                    <Center>
                        <Image
                            size={{base: "64"}}
                            resizeMode={"contain"}
                            alt="NativeBase Logo"
                            source={require("../../images/logo.png")}
                        />
                    </Center>

                    <Center
                        py={{
                            base: "4",
                            md: "4",
                        }}
                    >
                        <VStack
                            space="4"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text fontSize="4xl" fontWeight="bold" color="primary.900">
                                Seja bem-vindo!!
                            </Text>
                            <Text fontSize="xl" fontWeight="bold" color="primary.900">
                                Programa cashback da Palmed!
                            </Text>
                            <Text
                                _dark={{
                                    color: "coolGray.400",
                                }}
                                _light={{
                                    color: "primary.900",
                                }}
                            >
                                Aguarde... você será redirecionado!
                            </Text>
                        </VStack>
                    </Center>
                </Box>
            </VStack>;
        </>
    );
}
