import React from "react";
import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import {useAuthContext} from "../context/auth.context";
import SignIn from "../components/login/login.signin";//import OTP from "../components/login/login.otp";
import Forgot from "../components/login/login.forgot";
import Splash from "../components/splash";
import Loading from "../components/loading";
import Extrato from "../components/extrato/extrato";
import DeepLinkExtrato from "../components/deepLink/deepLinkExtrato";
import AvisoCliente from "../components/aviso/avisoCliente";

const Stack = createStackNavigator();
const StackHome = createStackNavigator();

export function Routes() {
    const {session, loading} = useAuthContext();

    function HomePages() {
        return (
            <StackHome.Navigator screenOptions={{headerShown: false}}>
                <StackHome.Screen name="Extrato" component={Extrato}/>
            </StackHome.Navigator>
        );
    }

    function RoutersDefault() {
        const title = 'Palmed - CashBack';
        const Stacks = () => {
            if (loading)
                return <>
                    < Stack.Navigator screenOptions={{headerShown: false}}>
                        <Stack.Screen name="LodingScreen"
                                      component={Loading}
                                      options={{title: title}}/>
                    </Stack.Navigator>
                </>

            if (session.usuarioPortal.IDUSUARIOPORTAL === 0) {
                return <>
                    < Stack.Navigator screenOptions={{headerShown: false}}>
                        <Stack.Screen name="Splash" component={Splash} options={{title: title}}/>
                        <Stack.Screen name="SignIn" component={SignIn} options={{title: title}}/>
                        <Stack.Screen name="Forgot" component={Forgot} options={{title: title}}/>
                        <Stack.Screen name="DeepLinkExtrato" component={DeepLinkExtrato} options={{title: title}}/>
                    </Stack.Navigator>
                </>
            }

            if (session.usuarioPortal.EXCECAO === 'S') {
                return <>
                    < Stack.Navigator screenOptions={{headerShown: false}}>
                        <Stack.Screen name="AvisoCliente" component={AvisoCliente} options={{title: title}}/>
                    </Stack.Navigator>
                </>
            }

            return <>
                < Stack.Navigator screenOptions={{headerShown: false}}>
                    <Stack.Screen name="Home" component={HomePages} options={{title: title}}/>
                </Stack.Navigator>
            </>
        }

        return (
            <NavigationContainer linking={{
                prefixes: ['grupopalmed://', 'https://cashback.grupopalmed.com.br'],
                config: {
                    screens: {
                        DeepLinkExtrato: 'dlextrato/:id/:type'
                    },
                },
            }}>
                <Stacks/>
            </NavigationContainer>)
    }

    function DefineRouter() {
        return (RoutersDefault())
    }

    return DefineRouter();
}
